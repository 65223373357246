import React from "react";
import { Page } from "../components/Page";
import Section from "../components/layouts/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faBluesky } from "@fortawesome/free-brands-svg-icons";

function Contact() {
  const twitter = "https://twitter.com/SnowtailGames";
  const bluesky = "https://bsky.app/profile/snowtailgames.bsky.social";
  const email = "mailto:info@snowtailgames.com";
  return (
    <Page title={"Contact"} path="/contact">
      <Section fullWidth={true} background="#0a0a0a">
        <div id="st-page-header" className="small-12 grid-x column">
          <img
            src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665392135/Website/Img/Contact_US_ksiwzq.png"
            className="fade-in-video st-header-background"
            alt="the_team"
            style={{ zIndex: -1, filter: "blur(8px)" }}
          />
          <div className=" center">
            <h1 style={{ textShadow: "2px 2px 5px #000" }}>GET IN TOUCH!</h1>
          </div>
        </div>
      </Section>

      <Section fullWidth={false}>
        <div className="small-12 large-6 column st-contact-container" style={{ padding: 20 }}>
          <div className="text-left column">
            <h2>
              <b>Social Media</b>
            </h2>
            <p>Follow us on all of your favorite social channels so that we can keep you in the loop!</p>
            <p>Don't forget to sign up for our email newsletter at the very bottom of this page.</p>
            <ul className="menu no-bullet text-left">
              <li>
                <a href={twitter} target="_blank" rel="noopener noreferrer" style={{ padding: 0, width: 50, marginLeft: -20 }}>
                  <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </a>
              </li>
              <li>
                <a href={bluesky} target="_blank" rel="noopener noreferrer" style={{ padding: 0, width: 50 }}>
                  <FontAwesomeIcon icon={faBluesky} size="2x" />
                </a>
              </li>
            </ul>
            <br />
            <br />
            <h3>
              <b>General Inquiries</b>
            </h3>
            <p>Do you have a support request or question? Get in touch!</p>
            <br />
            <a href={email} className="link-button" target="_blank" rel="noopener noreferrer">
              Contact Us!
            </a>
          </div>
        </div>

        <div className="hide-for-small-only large-6 column st-contact-container" style={{ padding: 20 }}>
          <div className="text-center column">
            <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1743632635/Website/Img/baby-kraken_2_azepak.gif" alt="kraken" />
          </div>
        </div>
      </Section>
    </Page>
  );
}

export default Contact;
