import React, { Component } from "react";
import GamesData from "../data/GamesData.json";
import GamePage from "../components/layouts/GamePage";
import Section from "../components/layouts/Section";

export default class BridgeKnightsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      knightsIndex: 0,
      knights: [
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345270/Website/Img/Bridge%20Knights/Cards/CardLayout_212_vn0xz0.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345271/Website/Img/Bridge%20Knights/Cards/CardLayout_214_spxyfw.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345271/Website/Img/Bridge%20Knights/Cards/CardLayout_213_z9n4yw.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345271/Website/Img/Bridge%20Knights/Cards/CardLayout_2_evu9wa.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345270/Website/Img/Bridge%20Knights/Cards/CardLayout_28_jieapy.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345270/Website/Img/Bridge%20Knights/Cards/CardLayout_211_wosx2b.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345270/Website/Img/Bridge%20Knights/Cards/CardLayout_22_rchgsx.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345270/Website/Img/Bridge%20Knights/Cards/CardLayout_210_a4828m.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665345270/Website/Img/Bridge%20Knights/Cards/CardLayout_24_byruhw.png",
      ],
      islands: [
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665349432/Website/Img/Bridge%20Knights/3_islands_state_1_vsfq3q.png",
        "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665349432/Website/Img/Bridge%20Knights/3_islands_state_2_zw4ded.png",
      ],
      islandIndex: 0,
      refresh: false,
      knightPosition: "",
      moved: false,
    };
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleIslandClick = this.handleIslandClick.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }

  handleCardClick() {
    let newIndex = this.state.knightsIndex + 1;

    if (newIndex >= this.state.knights.length) newIndex = 0;

    this.setState({
      knightsIndex: newIndex,
      refresh: true,
    });

    setTimeout(this.stopLoading, 200);
  }

  handleIslandClick() {
    this.setState({
      islandIndex: 1,
    });
  }

  handleUpdatePositionClick(position) {
    this.setState({
      knightPosition: position,
      moved: true,
    });
  }

  stopLoading() {
    this.setState({
      refresh: false,
    });
  }
  render() {
    var game = GamesData["bridgeKnights"];
    let islandStyle = { width: 600 };

    var { knightsIndex, knights, islands, islandIndex, knightPosition, moved, refresh } = this.state;

    return (
      <GamePage game={game} callToActionText="Get notified for the Kickstarter" path="/bridge-knights">
        <Section fullWidth={false} background="#1b1c1e">
          <div className="small-12 medium-6 column text-left" style={{ justifyContent: "center", blockSize: "fit-content" }}>
            <h4>
              <b>ABOUT THE GAME</b>
            </h4>
            <p>{game.description[0]}</p>
            <br />
            <p>{game.description[1]}</p>
          </div>

          <div className="small-12 medium-6 column text-center" style={{ position: "relative" }}>
            <img src={islands[islandIndex]} alt="bridge-knights-islands" style={islandStyle} />

            <img
              src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665351917/Website/Img/Bridge%20Knights/Red_Knight_iyvkeq.png"
              alt="bridgeKnight_redknight"
              className={`st-minigame-redknight ${knightPosition}`}
            />
            {islandIndex < 1 ? (
              <button
                className="st-minigame-bridge-knights-border hide-for-small-only hide-for-medium-only"
                style={{ position: "absolute", left: 155, top: 130 }}
                onClick={this.handleIslandClick}
              />
            ) : moved ? (
              <span></span>
            ) : (
              <div>
                <button
                  className="st-minigame-bridge-knights-border hide-for-small-only hide-for-medium-only"
                  style={{ position: "absolute", left: 265, top: 120, width: 60, height: 60 }}
                  onClick={() => this.handleUpdatePositionClick("position1")}
                />
                <button
                  className="st-minigame-bridge-knights-border hide-for-small-only hide-for-medium-only"
                  style={{ position: "absolute", left: 440, top: 120, width: 60, height: 60 }}
                  onClick={() => this.handleUpdatePositionClick("position2")}
                />
              </div>
            )}
          </div>
        </Section>

        <Section fullWidth={false} background="#1F1F1F">
          <div className="small-12 medium-4 text-center" style={{ minHeight: 440 }}>
            {refresh ? (
              <span></span>
            ) : (
              <img
                src={knights[knightsIndex]}
                alt="bridge-knights-card"
                className="st-bridge-knight-card"
                onClick={this.handleCardClick}
                style={{ borderRadius: "6%", cursor: "pointer", width: 300, marign: "auto" }}
              />
            )}
          </div>
          <div className="small-12 medium-1"></div>
          <div className="small-12 medium-7 column text-left">
            <br />
            <br />
            <h4>
              <b>FEATURES</b>
            </h4>
            <ul style={{ justifyContent: "center" }}>
              {game.features.map((content, index) => (
                <li key={`features-${index}`} style={{ marginBottom: 15 }}>
                  {content}
                </li>
              ))}
            </ul>
          </div>
        </Section>

        <Section fullWidth={false} background="#1b1c1e">
          <div className="small-12 medium-6 column text-center" style={{ padding: 10 }}>
            <a href="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665306275/Website/Img/Bridge%20Knights/steam_cover_qvxcns.png" target="blank">
              <img
                src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665306275/Website/Img/Bridge%20Knights/steam_cover_qvxcns.png"
                alt="bridge-knights-cover"
              />
            </a>
          </div>
          <div className="small-12 medium-6 column text-center" style={{ padding: 10 }}>
            <a href="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665348529/Website/Img/Bridge%20Knights/Board_render_bylogl.png" target="blank">
              <img
                src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665348529/Website/Img/Bridge%20Knights/Board_render_bylogl.png"
                alt="bridge-knights-cover"
              />
            </a>
          </div>
        </Section>
      </GamePage>
    );
  }
}
