import React, { Component } from "react";
import MailchimpForm from "../../MailchimpForm";
import Section from "../Section";

export default class NewsLetterSection extends Component {
  render() {
    return (
      <Section fullWidth={false}>
        <div className="grid-x hide-for-small-only hide-for-medium-only" style={{ minHeight: 290 }}>
          <div className="column large-3 text-left" style={{ position: "relative", top: -40 }}>
            <img
              style={{ position: "relative", width: 250 }}
              src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1743728916/Website/Img/pirate-a-celebrating-hit_u2ipi9.webp"
              className="hide-for-small-only hide-for-medium-only "
              alt="pirate"
            />
          </div>
          <div className="column grid-x small-12 large-9 text-left" style={{ position: "relative", padding: "0 20px" }}>
            <img
              style={{ position: "relative", height: 250, transform: "translateY(50%)" }}
              src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png"
              className="hide-for-small-only hide-for-medium-only"
              alt="speech_buble"
            />
            <div className="column grid-x small-12 large-9 text-left" style={{ position: "absolute", transform: "translateY(133%) translateX(26%)" }}>
              <h2 style={{ zIndex: 2 }}>Get Email Updates!</h2>
              <br />
              <MailchimpForm styleClass="st-input-text floating-label" />
            </div>
          </div>
        </div>
        <div className="column grid-x small-12 hide-for-large text-left" style={{ padding: "0 20px" }}>
          <h2>JOIN OUR NEWSLETTER</h2>
          <br />
          <MailchimpForm styleClass="st-input-text floating-label" />
        </div>
      </Section>
    );
  }
}
