import React, { Component } from "react";
import GamesData from "../../../data/GamesData.json";
import Section from "../Section";

export default class FeaturedGames extends Component {
  render() {
    let bluffparty = GamesData["bluffParty"];
    let bridgeKnights = GamesData["bridgeKnights"];
    return (
      <Section fullWidth={false}>
        <div className="column grid-x small-12 text-center text-center-in-small">
          <div className="small-12 column">
            <h2>OUR GAMES</h2>
          </div>
          <div className="small-12 medium-6 column">
            <a href={bluffparty.contentUrl} className="st-featured-game">
              <img src={bluffparty.featureImgSrc} alt={bluffparty.title}></img>
            </a>
            <p style={{ padding: "5px" }}>{bluffparty.subFeatureDecription}</p>
          </div>

          <div className="small-12 medium-6 column">
            <a href={bridgeKnights.contentUrl} className="st-featured-game">
              <img src={bridgeKnights.featureImgSrc} alt={bridgeKnights.title}></img>
            </a>
            <p style={{ padding: "5px 20px" }}>{bridgeKnights.subFeatureDecription}</p>
          </div>
        </div>
      </Section>
    );
  }
}
