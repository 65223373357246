import React, { useState, useEffect, useRef } from "react";
import { Page } from "../Page";
import Section from "../layouts/Section";
import MailchimpForm from "../MailchimpForm";

const GamePage = ({ game, callToActionText, children, path }) => {
  const [callToActionPressed, setCallToActionPressed] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 1024);
  const videoRef = useRef(null);

  const resize = () => {
    setMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);

  // Restore video playback position
  useEffect(() => {
    const video = videoRef.current;
    const savedTime = parseFloat(localStorage.getItem("videoTime") || "0");

    if (video && game.srcType === "video") {
      video.currentTime = savedTime;

      // Make sure it starts playing (some browsers may require interaction)
      const playPromise = video.play();
      if (playPromise?.catch) {
        playPromise.catch((e) => {
          console.warn("Autoplay prevented:", e);
        });
      }
    }

    return () => {
      if (video) {
        localStorage.setItem("videoTime", video.currentTime.toString());
      }
    };
  }, [game.src, game.srcType]);

  const handleClickCallToAction = () => {
    setCallToActionPressed(true);
  };

  return (
    <Page title={game.title} path={path}>
      <Section fullWidth={true}>
        <div id="st-page-header" className="small-12 column games text-center">
          {mobile ? (
            <img src={game.mobileSrc} className="fade-in-video st-header-background" alt={game.name} />
          ) : game.srcType === "video" ? (
            <video ref={videoRef} disablePictureInPicture autoPlay muted loop className="ignore-pointer fade-in-video st-header-background">
              <source src={game.src} type="video/mp4" />
            </video>
          ) : (
            <img src={game.src} className="fade-in-video st-header-background" alt={game.name} />
          )}

          <img id="st-game-logo" src={game.logo} alt={game.name} />

          {callToActionPressed ? (
            <div
              className="small-4 column text-left st-mailchimp-game fade-in"
              style={{
                justifyContent: "center",
                blockSize: "fit-content",
              }}
            >
              <MailchimpForm styleClass="st-input-text floating-label" />
            </div>
          ) : (
            <button id="st-game-call-to-action" onClick={handleClickCallToAction}>
              {callToActionText}
            </button>
          )}
        </div>
      </Section>
      {children}
    </Page>
  );
};

export default GamePage;
