import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const navRef = useRef(null);
  const toggleRef = useRef(null);

  const toggleMenu = () => {
    setNavOpen((prev) => !prev);
  };

  const closeMenu = () => {
    if (navOpen) setNavOpen(false);
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setShowNavbar(false); // scrolling down
    } else {
      setShowNavbar(true); // scrolling up
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <>
      {/* Toggle button outside the nav panel */}

      <nav id="st-navbar" className={`grid-x navbar-scroll ${showNavbar ? "visible" : "hidden"}`}>
        <button ref={toggleRef} className="menu-icon st-menu-icon text-right hide-for-large" onClick={toggleMenu} />

        <div id="st-navbar-container" ref={navRef} className={`column small-12 grid-x grid-padding-x align-middle ${navOpen ? "open" : ""}`}>
          <div className="column small-8 medium-8 large-4">
            <LeftNav />
          </div>

          <div id="st-navbar-menu" className={`column small-8 medium-8 ${navOpen ? "open" : ""}`}>
            <RightNav toggleMenu={toggleMenu} navOpen={navOpen} />
          </div>

          {navOpen && (
            <div
              className="hide-for-large"
              style={{
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.5)",
                position: "absolute",
                top: 60,
                left: 0,
              }}
              onClick={closeMenu}
            />
          )}
        </div>
      </nav>
    </>
  );
};

const LeftNav = () => (
  <div className="grid-x">
    <div id="st-logo" className="column small-12" style={{ paddingTop: 5 }}>
      <a href="/" style={{ pointerEvents: "visible" }}>
        <img
          src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442173/Website/Logo/156444206ww917657637_transparent_ome38c.png"
          className="st-logo-nav"
          alt="logo"
        />
        <img
          src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442058/Website/Logo/156444206917657637_transparent_ic8u9o.png"
          className="st-logo-nav-name"
          alt="logo"
        />
      </a>
    </div>
  </div>
);

const RightNav = ({ toggleMenu, navOpen }) => {
  const activeClass = ({ isActive }) => (isActive ? "active-link" : "");

  return (
    <div className={"column small-12 flex-right-desktop"}>
      <div className="nav-item-with-submenu">
        <NavLink to="/bluff-party" className={({ isActive }) => `responsive-nav-button ${activeClass({ isActive })}`} onClick={toggleMenu}>
          <span id="navItem" className="st-navigation-menu">
            Bluff Party
          </span>
        </NavLink>
        <ul className="submenu" style={{ listStyleType: "none" }}>
          <li>
            <NavLink to="/bluff-party/bullship" className={({ isActive }) => `responsive-nav-button ${activeClass({ isActive })}`} onClick={toggleMenu}>
              <span className="st-submenu-item">Bullship</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <NavLink to="/bridge-knights" className={({ isActive }) => `responsive-nav-button ${activeClass({ isActive })}`} onClick={toggleMenu}>
        <span id="navItem" className="st-navigation-menu">
          Bridge Knights
        </span>
      </NavLink>

      <NavLink to="/about" className={({ isActive }) => `responsive-nav-button ${activeClass({ isActive })}`} onClick={toggleMenu}>
        <span id="navItem" className="st-navigation-menu">
          The Team
        </span>
      </NavLink>

      <NavLink to="/contact" className={({ isActive }) => `responsive-nav-button ${activeClass({ isActive })}`} onClick={toggleMenu}>
        <span id="navItem" className="st-navigation-menu">
          Contact
        </span>
      </NavLink>
    </div>
  );
};

export default Navigation;
