import React, { useEffect, useState } from "react";
import GamesData from "../data/GamesData.json";
import GamePage from "../components/layouts/GamePage";
import Section from "../components/layouts/Section";
import BluffPartyGames from "../components/bluffparty/BluffPartyGames";

const BullshipHome = () => {
  const game = GamesData["bullship"];
  const [mobile, setMobile] = useState(window.innerWidth <= 600);
  const resize = () => {
    setMobile(window.innerWidth <= 600);
  };

  // Handle window resize
  useEffect(() => {
    window.addEventListener("resize", resize);
    resize(); // Initial check
    return () => window.removeEventListener("resize", resize);
  }, []);

  // Resto

  return (
    <GamePage game={game} callToActionText="Sign up for email updates" path="/bluff-party/bullship">
      <Section fullWidth={false} background="#1b1c1e">
        <div className="small-12 medium-6 column text-left" style={{ justifyContent: "center", blockSize: "fit-content" }}>
          <h4>
            <b>ABOUT THE GAME</b>
          </h4>
          <p>{game.description[0]}</p>
          <p>{game.description[1]}</p>
        </div>

        <div className="small-12 medium-6 column text-center " style={{ position: "relative", paddingLeft: 15, display: mobile ? "none" : "block" }}>
          <img
            src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1743779503/Website/Img/Screenshot01_idn2ss.webp"
            alt="game-screenshot"
            className="hide-for-smallest-devices"
          />
        </div>
      </Section>

      {game.features.map((feature, index) => (
        <Section background="#1F1F1F" alignCenter={true}>
          {mobile ? (
            <div style={{ backgroundColor: "#1F1F1F", display: "flex", alignItems: "center" }} className={`grid-x small-12 st-section`}>
              <div className="small-12 medium-6 column text-center " style={{ paddingBottom: 15 }}>
                <img src={feature.img} alt={"feature-" + index} />
              </div>
              <div className="small-12 medium-6 column text-left">
                <h4>
                  <b>{feature.title}</b>
                </h4>
                {feature.texts.map((text, subIndex) => (
                  <p key={subIndex}>{text}</p>
                ))}
              </div>
            </div>
          ) : (
            <>
              {index % 2 === 0 ? (
                <>
                  <div className="small-12 medium-6 column text-center " style={{ padding: 15 }}>
                    <img src={feature.img} alt={"feature-" + index} />
                  </div>
                  <div className="small-12 medium-6 column text-left">
                    <h4 style={{ textTransform: "uppercase" }}>
                      <b>{feature.title}</b>
                    </h4>
                    {feature.texts.map((text, subIndex) => (
                      <p key={subIndex}>{text}</p>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="small-12 medium-6 column text-left" style={{ padding: 15 }}>
                    <h4 style={{ textTransform: "uppercase" }}>
                      <b>{feature.title}</b>
                    </h4>
                    {feature.texts.map((text, subIndex) => (
                      <p key={subIndex}>{text}</p>
                    ))}
                  </div>
                  <div className="small-12 medium-6 column text-center ">
                    <img src={feature.img} alt={"feature-" + index} />
                  </div>
                </>
              )}
            </>
          )}
        </Section>
      ))}
      <BluffPartyGames />
    </GamePage>
  );
};

export default BullshipHome;
