import React, { Component } from "react";

export default class Section extends Component {
  render() {
    const { fullWidth, className, background, children, sx, alignCenter, noPadding } = this.props;

    return (
      <div style={sx} className={`grid-x text-center`}>
        <div
          style={{ backgroundColor: background, display: alignCenter ? "flex" : "", alignItems: alignCenter ? "center" : "", padding: noPadding && 0 }}
          className={`grid-x small-12 ${!fullWidth && "st-section-container"}  ${fullWidth ? "" : "st-section "}${className ? className : ""}`}
        >
          {children}
        </div>
      </div>
    );
  }
}
