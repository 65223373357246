import React, { Component } from "react";
import GamesData from "../data/GamesData.json";
import GamePage from "../components/layouts/GamePage";
import Section from "../components/layouts/Section";
import BluffPartyGames from "../components/bluffparty/BluffPartyGames";

export default class BluffPartyHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.stopLoading = this.stopLoading.bind(this);
  }

  stopLoading() {
    this.setState({
      refresh: false,
    });
  }
  render() {
    var game = GamesData["bluffParty"];

    return (
      <GamePage game={game} callToActionText="Sign up for email updates" path="/bridge-knights">
        <Section fullWidth={false} background="#1b1c1e">
          <div className="column small-12 medium-5 column text-left" style={{ justifyContent: "center", blockSize: "fit-content", paddingBottom: 10 }}>
            <h4>
              <b>ABOUT THE GAME</b>
            </h4>
            <p style={{ margin: 0 }}>{game.description[0]}</p>
            <br />
            <p style={{ margin: 0 }}>{game.description[1]}</p>
          </div>
          <div className="small-12 medium-1" />

          <div
            className="column small-12 medium-5 column text-center"
            style={{ position: "relative", justifyContent: "right", display: "flex", alignItems: "center" }}
          >
            <img src={"https://res.cloudinary.com/dnhvfarsi/image/upload/v1743635543/Website/Img/Web_to_TV_2_go98mz.webp"} alt="screenToTV" />
          </div>
        </Section>
        <BluffPartyGames />
      </GamePage>
    );
  }
}
