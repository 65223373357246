import React, { Component } from "react";
import { Page } from "../components/Page";
import Section from "../components/layouts/Section";
import { Profile } from "../data/AboutData";

function About() {
  return (
    <Page title="The Team" path="/about">
      <Section fullWidth={true} background="#0a0a0a">
        <div id="st-page-header" className="small-12 grid-x column">
          <img
            src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665392135/Website/Img/Contact_US_ksiwzq.png"
            className="fade-in-video st-header-background"
            alt="the_team"
            style={{ zIndex: -1, filter: "blur(8px)" }}
          />
          <div className=" center">
            <h1 style={{ textShadow: "2px 2px 5px #000" }}>
              <b>THE TEAM</b>
            </h1>
          </div>
        </div>
      </Section>

      <Section fullWidth={false} background="#1b1c1e" className="align-center">
        {Profile.map((content, index) => (
          <TheDev
            key={`dev-${index}`}
            name={content.name}
            title={content.title}
            img={content.imgSrc}
            hoverImg={content.hoverImgSrc}
            className={content.className}
            background={content.background}
            text={content.description}
            favouriteTitle={content.favouriteGamesTitle}
            favouriteGames={content.favouriteGames}
          />
        ))}
      </Section>
      <Section fullWidth={false} sx={{ overflow: "hidden" }} noPadding={true}>
        <div className="small-12 grid-x column about-box">
          <img
            alt="stphoto"
            src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1743774861/Website/Img/team-photo_suis1f.webp"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              zIndex: 2,
              filter: "blur(5px)",
            }}
          />
          <div className="small-12 column text-center"></div>

          <div className="small-12 column text-center" style={{ zIndex: 5, padding: 20 }}>
            <h2 style={{ textShadow: "5px 5px 5px #000" }}>
              <b>ABOUT SNOWTAIL</b>
            </h2>
            <p style={{ maxWidth: "60ch", margin: "auto", textShadow: "5px 5px 5px #000" }}>
              A Montreal-based independent game studio. Snowtail is a group of close friends with different talents passionately working together to build great
              games. We want to build fun and wonderous worlds filled with charming characters and memorable moments. We all started in our mother’s basements
              and have made it all the way to our own basements.
              <br />
              <br />
              Who knows where we'll go next!
            </p>
          </div>
        </div>
      </Section>
    </Page>
  );
}

export default About;

class TheDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };

    this.onHoverEnter = this.onHoverEnter.bind(this);
    this.onHoverExit = this.onHoverExit.bind(this);
  }

  onHoverEnter() {
    this.setState({
      isHovered: true,
    });
  }

  onHoverExit() {
    this.setState({
      isHovered: false,
    });
  }

  render() {
    const { name, title, img, hoverImg, text, background, className, favouriteTitle, favouriteGames } = this.props;
    const { isHovered } = this.state;

    return (
      <div className="cell large-4 medium-6 small-12">
        <div className="st-dev-img-container" style={{ backgroundColor: background }} onMouseEnter={this.onHoverEnter} onMouseLeave={this.onHoverExit}>
          {isHovered ? <img src={hoverImg} alt={name} className="fade-in" /> : <img src={img} alt={name} className={`st-profile-img fade-in ${className}`} />}
        </div>
        <div className="column small-12 st-profile-description-box">
          <h3 style={{ lineHeight: 1, textTransform: "uppercase" }}>
            <b>{name}</b>
          </h3>
          <h5>{title}</h5>
          <p style={{ paddingLeft: 20, paddingRight: 20, margin: 0 }}>{text}</p>
        </div>
        <div className="small-12 show hide-in-large st-profile-divider" />

        <div className="column small-12" style={{ padding: 10, color: "#777777" }}>
          <h5>
            <b>{favouriteTitle}</b>
          </h5>
          <ul className="no-bullet">
            {favouriteGames.map((content, index) => (
              <li key={`favourite-game-${index}`}>{content}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
