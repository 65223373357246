import React, { Component } from "react";
import GamesData from "../../data/GamesData.json";
import Section from "../layouts/Section";

export default class BluffPartyGames extends Component {
  render() {
    var game = GamesData["bluffParty"];

    return (
      <Section fullWidth={false} style={{ paddingTop: 0 }} background="#1f1f1f">
        {game.games.map((content, index) => (
          <div
            className="small-6 medium-6 column text-left"
            style={{ justifyContent: "center", blockSize: "fit-content", overflow: "hidden", position: "relative" }}
          >
            <a href={content.available ? content.contentUrl : undefined} className="st-hover-scale" style={{}}>
              <img src={content.imgSrc} alt={content.name} />
              <div className={!content.available ? "bluff-party-games-title coming-soon" : "bluff-party-games-title"}>{content.name}</div>
              {!content.available && (
                <div className="hover-overlay">
                  <div className="hover-text">Coming Soon</div>
                </div>
              )}
            </a>
          </div>
        ))}
      </Section>
    );
  }
}
