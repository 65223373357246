import React, { useState, useEffect, useRef, useCallback } from "react";
import { Data } from "../../../data/CarouselData";

export default function Carousel() {
  const [index, setIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const videoRef = useRef(null);
  const autoScrollInstance = useRef(null);

  const resize = useCallback(() => {
    setIsMobile(window.innerWidth <= 1024);
  }, []);

  const stopAutoScroll = () => {
    if (autoScrollInstance.current) {
      clearTimeout(autoScrollInstance.current);
      autoScrollInstance.current = null;
    }
  };

  const stopLoading = () => {
    setRefresh(false);
  };

  const autoScroll = useCallback(() => {
    stopAutoScroll(); // prevent stacking
    autoScrollInstance.current = setTimeout(() => {
      onAutoScroll();
    }, 20000);
  }, [index]);

  const onAutoScroll = useCallback(() => {
    const newIndex = (index + 1) % Data.length;
    changeIndexValue(newIndex);
  }, [index]);

  const changeIndexValue = (newIndex) => {
    stopAutoScroll(); // Clear before setting new
    setRefresh(true);
    setIndex(newIndex);
    setTimeout(stopLoading, 200);
    autoScroll(); // Restart timer
  };

  const handleIndexChange = (e) => {
    const newIndex = parseInt(e.target.value);
    stopAutoScroll(); // Clear timer on manual change
    setRefresh(true);
    setIndex(newIndex);
    setTimeout(stopLoading, 200);
    autoScroll(); // Restart timer
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    autoScroll(); // start on mount

    return () => {
      window.removeEventListener("resize", resize);
      stopAutoScroll(); // cleanup
    };
  }, [resize, autoScroll]);

  useEffect(() => {
    if (videoRef.current && Data[index].srcType === "video") {
      const video = videoRef.current;
      const currentSrc = video.querySelector("source").src;
      const newSrc = Data[index].src;

      if (currentSrc !== newSrc) {
        video.pause();
        video.querySelector("source").src = newSrc;
        video.load();
        video.play();
      }
    }
  }, [index]);

  return (
    <div id="st-header" className={`grid-x text-center st-carousel ${refresh ? "disabled" : ""}`} onMouseEnter={stopAutoScroll} onMouseLeave={autoScroll}>
      {refresh ? (
        <span></span>
      ) : isMobile ? (
        <img src={Data[index].mobileSrc} className="fade-in-video" alt={Data[index].name} />
      ) : Data[index].srcType === "video" ? (
        <video ref={videoRef} disablePictureInPicture autoPlay muted loop preload="auto" className="ignore-pointer fade-in-video">
          <source src={Data[index].src} type="video/mp4" />
        </video>
      ) : (
        <img src={Data[index].src} className="fade-in-video" alt={Data[index].name} />
      )}

      <div className={`column st-carousel-labels ${isMobile ? "st-carousel-mobile" : ""}`} style={{ zIndex: 1 }}>
        {Data[index].type === "game" ? (
          <>
            <img id="st-game-carousel-logo" className="fade-in" src={Data[index].logo} alt={Data[index].title} />
            <br />
            <br />
          </>
        ) : (
          <h1 className="st-carousel-label">{Data[index].title}</h1>
        )}

        <div className="column small-4 text-center" style={{ display: "inline-flex", transform: "translate(0,-13px)" }}>
          <h5>
            <small style={{ color: "#fff" }}>{Data[index].description}</small>
          </h5>
        </div>

        <br />
        {!refresh && (
          <a href={Data[index].url} type="button" className="st-carousel-callToAction">
            {Data[index].callToAction}
          </a>
        )}
      </div>

      <br />

      <div className="column" style={{ position: "absolute", bottom: 5 }}>
        <form>
          <ul className="st-carousel-navigation">
            {Data.map((content, i) => (
              <li key={`${content.name}-${i}`}>
                <input type="radio" name="carousel" value={i} checked={i === index} onClick={handleIndexChange} onChange={() => {}} disabled={refresh} />
              </li>
            ))}
          </ul>
        </form>
      </div>
    </div>
  );
}
